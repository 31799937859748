import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../redux/actions/authAction';
import Avatar from '../Avatar';
import NotifyModal from '../NotifyModal';

const Menu = () => {
    const [isMobile, setIsMobile] = useState(false);

    // Check if the screen width is mobile size
    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 766);  // Adjust the width as needed for mobile
        };
        checkMobile();  // Initial check
        window.addEventListener('resize', checkMobile);  // Add resize event listener

        return () => window.removeEventListener('resize', checkMobile);  // Clean up event listener
    }, []);

    const navLinks = [
        { label: 'Home', icon: 'home', path: '/' },
        { label: 'Message', icon: 'forum', path: '/message' },
        { label: 'Find People', icon: 'people', path: '/findPeople' },
        // { label: 'Contact Us', icon: 'help', path: '/contactUs' },
    ];

    const { auth, notify } = useSelector(state => state);
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const isActive = (pn) => {
        return pn === pathname ? 'active' : '';
    };

    return (
        <div className="menu">

            <ul className="navbar-nav flex-row">
                {navLinks.map((link, index) => (
                    <li className={`nav-item px-2 ${isActive(link.path)}`} key={index}>
                        <Link className="nav-link" to={link.path}>
                            <span className="material-icons">{link.icon}</span>
                        </Link>
                    </li>
                ))}

                {/* Notification dropdown */}
                <li className={`nav-item ${isMobile ? 'dropup' : 'dropdown'}`} style={{ opacity: 1 }}>
                    <span className="nav-link position-relative" id="navbarDropdown"
                        role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className="material-icons" style={{ color: notify.data.length > 0 ? 'crimson' : '' }}>
                            notifications
                        </span>
                        <span className="notify_length">{notify.data.length}</span>
                    </span>

                    <div 
                        className="dropdown-menu" 
                        aria-labelledby="navbarDropdown"
                        style={{ transform: `translateX(${isMobile ? '75px' : '0px'})` }}>
                        <NotifyModal />
                    </div>
                </li>

                {/* Avatar dropdown */}
                <li className={`nav-item ${isMobile ? 'dropup' : 'dropdown'}`} style={{ opacity: 1 }}>
                    <span className="nav-link" id="navbarDropdown"
                        role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <Avatar src={auth.user.avatar} size="small-avatar" className="avatar"/>
                    </span>

                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <Link className="dropdown-item" to={`/profile/${auth.user._id}`} style={{ color: "#31005d", display: 'flex', alignItems: 'center' }}>
                            <span className="material-icons" style={{ color: "#31005d" }}>person</span>
                            <b>Profile</b>
                        </Link>

                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to={`/contactUs`} style={{ color: "#31005d", display: 'flex', alignItems: 'center' }}>
                            <span className="material-icons" style={{ color: "#31005d" }}>help</span>
                            <b>Contact Us</b>
                        </Link>

                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to={`/getTheApp`} style={{ color: "#31005d", display: 'flex', alignItems: 'center' }}>
                            <span className="material-icons" style={{ color: "#31005d" }}>download</span>
                            <b>Get The App</b>
                        </Link>

                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to="/"
                            onClick={() => dispatch(logout())} style={{ color: "crimson", display: 'flex', alignItems: 'center' }}>
                            <span className="material-icons" style={{ color: "crimson" }}>logout</span>
                            <b>Logout</b>
                        </Link>
                    </div>
                </li>
            </ul>
        </div>
    );
};

export default Menu;
