import React from 'react'
import "../../../styles/GetTheApp.css"

const GetTheApp = () => {
    return (
        <div className='GetTheApp'>
            <div className="title"> <b> Desktop Application </b> </div>

            <div className='description' style={{fontFamily: "verdana"}}>
                Having a desktop software version of <a href="https://www.foodbyte.net"> <b> Foodbyte </b> </a> can bring several key advantages to enhance user experience and functionality. First, it would allow users to access essential features of Foodbyte, such as managing food orders, browsing menus, or tracking deliveries, ensuring convenience in areas with unreliable internet connectivity. Additionally, a desktop app could offer faster performance by utilizing local system resources, making it ideal for processing complex tasks or handling larger datasets like inventory management or analytics. The desktop version can also provide a more seamless experience by integrating with the operating system’s native features, such as notifications for order updates, file system access for storing receipts or customer data, and better keyboard shortcuts for power users. Security is another critical factor, as the app could store sensitive data like customer details or transaction histories locally with encryption, minimizing the risk of data breaches during transmission. Moreover, a desktop application for Foodbyte could enable more advanced customization and interaction, like allowing restaurant staff to manage orders or inventory more efficiently with access to local files or connected devices, such as printers or cash registers. By offering a robust desktop solution alongside the website, Foodbyte can enhance both user convenience and operational efficiency, helping it stand out in the competitive food service technology market.

                <br /> <br />

                Now, you can get the desktop application for your Windows Operating System. <b style={{color: "blue"}}> Click the below image to download. </b>
            </div>

            <a href="https://www.mediafire.com/file/9m5d5elrdimikqt/FoodbyteSetup.exe/file" download="FoodbyteSetup.exe" target='blank' style={{textDecoration: "none"}}> <img src="/assets/images/microsoft.png" alt="Work in progress!" /> </a>

            {/* <div className='description_2' style={{fontFamily: "verdana"}}>
                Sometimes, tutorial can be very helpful to download and install a software successfully. Here is a video tutorial to make your way easier so that you don't feel interupt. Good luck!
            </div> */}

            <div class="video_tutorial">

            </div>
        </div>
    )
}

export default GetTheApp