import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../redux/actions/authAction';
import { useParams, Link } from 'react-router-dom';
import Logo from "../images/foodbyte poster.png";
import Footer from "../components/footer/Footer";

const ResetPassword = () => {
    const { alert: alertState } = useSelector(state => state);  // Rename to `alertState`
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const dispatch = useDispatch();
    const { id, token } = useParams();  // Extract id and token from URL
    const [typePass, setTypePass] = useState(false);
    const [typeCfPass, setTypeCfPass] = useState(false);

    const handleSubmit = e => {
        e.preventDefault();
        if (password !== confirmPassword) {
            return window.alert("Passwords do not match");  // Use `window.alert`
        }

        // Pass both id and token along with the new password
        dispatch(resetPassword(id, token, password));
    }

    return (
        <div className="auth_page">
            <img className='logo' src={Logo} alt="Foodbyte" />
            <div className='footer'>
                <Footer />
            </div>
            <form onSubmit={handleSubmit}>
                <h3 className="text-uppercase text-center mb-4" style={{ color: "#30115d" }}><b>Reset Password</b></h3>

                <div className="form-group">
                    <label htmlFor="password"> <b>New Password</b> </label>
                    <div className="pass">
                        <input type={typePass ? "text" : "password"}
                            className="form-control" id="password" name="password"
                            onChange={(e) => setPassword(e.target.value)} value={password}
                            style={{ border: "2px solid #31005d", background: `${alertState.password ? '#fd2d6a14' : ''}` }} />

                        <small onClick={() => setTypePass(!typePass)}>
                            <b> {typePass ? 'Hide' : 'Show'} </b>
                        </small>
                    </div>

                    <small className="form-text text-danger">
                        {alertState.password ? alertState.password : ''}
                    </small>
                </div>

                <div className="form-group">
                    <label htmlFor="confirmPassword"> <b>Confirm Password</b> </label>
                    <div className="pass">
                        <input type={typeCfPass ? "text" : "password"}
                            className="form-control" id="confirmPassword" name="confirmPassword"
                            onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword}
                            style={{ border: "2px solid #31005d", background: `${alertState.cf_password ? '#fd2d6a14' : ''}` }} />

                        <small onClick={() => setTypeCfPass(!typeCfPass)}>
                            <b> {typeCfPass ? 'Hide' : 'Show'} </b>
                        </small>
                    </div>

                    <small className="form-text text-danger">
                        {alertState.cf_password ? alertState.cf_password : ''}
                    </small>
                </div>

                <button type="submit" className="btn btn-dark w-100" disabled={!password || !confirmPassword}>
                    Reset Password
                </button>

                <p className="my-2">
                    <br />
                    If you don't need it, <Link to="/" style={{ color: "#31005d", textDecoration: "none" }}> <b> Get back to login </b> </Link>
                </p>
            </form>
        </div>
    );
}

export default ResetPassword;